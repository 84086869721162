const menuItems = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'ti-home',
        // badge: {
        //     variant: "primary",
        //     text: "2"
        // },
        link: '/'
    },
    {
        id: 2,
        label: 'Support',
        icon: 'ti-headphone',
        subItems: [
            {
                id: 3,
                label: 'Customer Chats',
                link: '/chat-support/customers'
            },
            {
                id: 23,
                label: 'Admins Chats',
                link: '/chat-support/admins'
            },
        ]
    },
    {
        id: 4,
        label: 'Bookings',
        icon: 'ti-briefcase',
        subItems: [
            {
                id: 5,
                label: 'Flight Bookings',
                link: '/flight-bookings'
            },
            {
                id: 6,
                label: 'Amadeus Bookings',
                link: '/amadeus-bookings'
            },
            // {
            //     id: 7,
            //     label: 'Hotel Bookings',
            //     link: '/'
            // }
        ]
    },
    {
        id: 8,
        label: 'Pages',
        icon: 'ti-files',
        link: '/site-pages'
    },
    {
        id: 9,
        label: 'Payments',
        icon: 'ti-wallet',
        link: '/coupons',
        subItems: [
            {
                id: 10,
                label: 'Pending Requests',
                link: '/payment/pending-request'
            },
            {
                id: 11,
                label: 'Transactions',
                link: '/payment/transactions'
            },
            {
                id: 12,
                label: 'Wallet',
                link: '/payment/wallet'
            }
        ]
    },
    {
        id: 13,
        label: 'Individual users',
        icon: 'ti-user',
        link: '/users/individuals'
    },
    {
        id: 14,
        label: 'Business users',
        icon: 'ti-bag',
        link: '/companies'
    },
    {
        id: 17,
        label: 'Price settings',
        icon: 'ti-money',
        subItems: [
            // {
            //     id: 18,
            //     label: 'Discounts Configurations',
            //     link: '/companies'
            // },
            {
                id: 19,
                label: 'Markup Configurations',
                link: '/markup-configurations'
            },
            {
                id: 20,
                label: 'coupons',
                link: '/coupons'
            },
        ]
    },
    {
        id: 21,
        label: 'Site Supervisors',
        icon: 'ti-user',
        subItems: [
            {
                id: 22,
                label: 'users',
                link: '/supervisors'
            },
            {
                id: 23,
                label: 'permissions',
                link: '/supervisors/permissions'
            },
        ]
    },
]

export {
    menuItems
};